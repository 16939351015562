import { CContentRouteNames } from '@/domain/Content/contracts/CContentRouteNames'
import type { RouteRecordRaw } from 'vue-router'

export const contentRoutes: RouteRecordRaw[] = [
  {
    path: '/:slug',
    name: CContentRouteNames.default,
    component: () => import('@/domain/Content/components/PContent.vue'),
  },
  {
    path: '/cases/:slug',
    name: CContentRouteNames.cases,
    component: () => import('@/domain/Content/components/PCase.vue'),
  },
  {
    path: '/books/:slug',
    name: CContentRouteNames.books,
    component: () => import('@/domain/Content/components/PCase.vue'),
  },
]
