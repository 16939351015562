<template>
  <FlexibleSectionLayout
    v-if="props.title"
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClassesComputed"
    :content-container-classes="contentContainerClassesComputed"
  >
    <div>
      <TextHeading
        :title-tag="EHeadline.H2"
        :content="{ title: props.title, subline: props.subline }"
        :classes="{
          title: 'text-2xl',
          subline: 'text-base font-normal',
          category: 'hidden',
        }"
      />
    </div>

    <div class="w-full flex flex-col sm:flex-row gap-12 xl:gap-14 2xl:gap-18">
      <div
        class="flex flex-col gap-6 w-full text-center md:w-1/2 rounded-lg bg-state-warning p-11"
      >
        <TextHeading
          :title-tag="EHeadline.H3"
          :content="{ title: props?.ctaBox?.title }"
          :classes="{ title: 'text-2xl' }"
        />

        <p
          v-if="!isEmpty(props?.ctaBox?.description)"
          class="text-base font-normal text-base-500"
          v-html="props?.ctaBox?.description"
        />

        <CallToActionButton
          v-if="
            props?.ctaBox?.cta?.title &&
            props?.ctaBox?.cta?.title.length > 0 &&
            props?.ctaBox?.cta?.target.length > 0 &&
            props?.ctaBox?.cta?.target.length > 0
          "
          :identifier="EUserEvents.selectedACase"
          :title="props?.ctaBox.cta.title"
          action-type="url"
          :action-target="props?.ctaBox.cta.target"
          class="pt-2"
        />
      </div>

      <ul class="flex flex-col w-full justify-center md:w-1/2 gap-6">
        <li v-for="(step, index) in props?.steps ?? []" :key="index">
          <div class="flex flex-row justify-start gap-4 items-center">
            <FlowBiteOrderedListIcon
              v-if="step?.icon && step.icon === TCaseMethodologyStepIcons.ORDERED_LIST"
              class="w-6 h-6 text-base-content"
            />
            <FlowBiteFaceGrinOutlineIcon
              v-if="step?.icon && step.icon === TCaseMethodologyStepIcons.FACE_GRIN"
              class="w-6 h-6 text-base-content"
            />
            <FlowBiteEditOutlineIcon
              v-if="step?.icon && step.icon === TCaseMethodologyStepIcons.EDIT_OUTLINE"
              class="w-6 h-6 text-base-content"
            />
            <TextHeading
              :title-tag="EHeadline.H4"
              :content="{ ...step }"
              :classes="{
                container: 'mt-auto',
                title: 'mt-0 text-xl font-semibold',
              }"
            />
          </div>
          <p v-if="!isEmpty(step?.description)" class="mt-2" v-html="step.description" />
        </li>
      </ul>
    </div>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'CaseMethodologySection'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { cva } from 'class-variance-authority'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { isEmpty } from 'lodash-es'
import type { TCaseMethodologyContent } from '@/domain/Content/contracts/TCaseMethodologyContent'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import CallToActionButton from '@/domain/Content/components/CallToActionButton.vue'
import FlowBiteOrderedListIcon from '@/domain/Content/components/icons/FlowBiteOrderedListIcon.vue'
import FlowBiteFaceGrinOutlineIcon from '@/domain/Content/components/icons/FlowBiteFaceGrinOutlineIcon.vue'
import FlowBiteEditOutlineIcon from '@/domain/Content/components/icons/FlowBiteEditOutlineIcon.vue'
import { TCaseMethodologyStepIcons } from '@/domain/Content/contracts/TCaseMethodologyStepIcons'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    TCaseMethodologyContent & {
      containerClasses?: string
      contentContainerClasses?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } & { attributes?: any }
  >(),
  {
    containerClasses: undefined,
    contentContainerClasses: 'flex-col gap-6 justify-center items-start',
  },
)

const containerClassesComputed = computed(() => cva()({ class: props.containerClasses }))

const contentContainerClassesComputed = computed(() =>
  cva()({ class: props.contentContainerClasses }),
)
</script>

<style scoped></style>
