<template>
  <FlexibleSectionLayout
    v-if="props.title && props?.testimonials[0]?.name"
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClassesComputed"
    :content-container-classes="contentContainerClassesComputed"
  >
    <div>
      <TextHeading
        :title-tag="EHeadline.H2"
        :content="{
          title: props.title,
          category: props.category,
          subline: props.subline,
        }"
        :classes="{
          title: 'text-2xl',
          subline: 'text-base font-normal',
        }"
      />
    </div>

    <ul
      class="w-full flex flex-col items-center justify-center sm:flex-row sm:flex-wrap mt-2 lg:mt-4 gap-6 xl:gap-14 2xl:gap-18"
    >
      <li
        v-for="(testimonial, index) in props.testimonials"
        :key="index"
        class="flex flex-col shrink-0 w-[300px] 2xs:w-[312px] min-h-[220px] border border-base-150 py-8 px-6 shadow-lg md:shadow-xl rounded-lg"
      >
        <div class="flex flex-row gap-3 overflow-hidden">
          <ProfileAvatarWithFallback
            :name="testimonial.name"
            :image="testimonial.image"
            class="w-12 h-12 border border-base-150"
          />

          <div class="flex flex-col">
            <div class="flex flex-row items-center gap-2">
              <span
                class="text-base font-semibold max-w-[166px] 2xs:max-w-[176px] whitespace-nowrap overflow-hidden text-ellipsis"
                v-html="testimonial.name"
              />
              <FlowBiteBadgeCheckIcon
                v-if="testimonial.hasCheckMark"
                class="w-4 h-4 text-blue-500"
              />
            </div>
            <span
              class="text-base font-normal max-w-[192px] 2xs:max-w-[204px] whitespace-nowrap overflow-hidden text-ellipsis"
              v-html="testimonial.handle"
            />
          </div>
        </div>

        <p class="text-base font-normal base-500 mt-4" v-html="testimonial.content" />
      </li>
    </ul>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'CaseTestimonialsSection'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { cva } from 'class-variance-authority'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import type { TCaseTestimonialsContent } from '@/domain/Content/contracts/TCaseTestimonialsContent'
import FlowBiteBadgeCheckIcon from '@/domain/Content/components/icons/FlowBiteBadgeCheckIcon.vue'
import ProfileAvatarWithFallback from '@/domain/Content/components/ProfileAvatarWithFallback.vue'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    TCaseTestimonialsContent & {
      containerClasses?: string
      contentContainerClasses?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } & { attributes?: any }
  >(),
  {
    containerClasses: undefined,
    contentContainerClasses: 'flex-col gap-6 justify-center items-start',
  },
)

const containerClassesComputed = computed(() => cva()({ class: props.containerClasses }))

const contentContainerClassesComputed = computed(() =>
  cva()({ class: props.contentContainerClasses }),
)
</script>

<style scoped></style>
