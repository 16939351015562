import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/CaseIntroSection.vue'
import { ESpecTypes } from '@/domain/Content/contracts/ESpecTypes'
import { EMediaType } from '@/domain/Content/contracts/EMediaType'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'

const supportedCtaList = ['SignInWithMagicUriForm']

export const CaseIntroSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'CaseIntroSection',
  friendlyName: 'CaseIntroSection',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  childRequirements: {
    message: `Use ${supportedCtaList.join(', ')} components`,
    query: {
      'component.name': { $in: supportedCtaList },
    },
  },
  inputs: [
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'titleTag',
      type: 'string',
      enum: toKeyValue(EHeadline),
    },
    {
      friendlyName: 'Title Classes',
      name: 'titleClasses',
      type: 'text',
    },
    {
      name: 'subline',
      type: 'longText',
    },
    {
      friendlyName: 'Subline Classes',
      name: 'sublineClasses',
      type: 'text',
    },
    {
      name: 'claim',
      type: 'longText',
    },
    {
      friendlyName: 'Claim Classes',
      name: 'claimClasses',
      type: 'text',
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      defaultValue: 'mt-12 mb-6',
      type: 'string',
    },
    {
      friendlyName: 'Spec entries',
      name: 'specs',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'type',
          type: 'string',
          defaultValue: ESpecTypes.ACTIONS,
          enum: Object.values(ESpecTypes),
        },
        {
          name: 'value',
          type: 'longText',
        },
      ],
    },
    {
      name: 'media',
      type: 'object',
      subFields: [
        {
          name: 'type',
          type: 'string',
          helperText: 'Type decides whether Image or Video is selected',
          defaultValue: EMediaType.image,
          enum: Object.values(EMediaType),
        },
        {
          name: 'sourceFile',
          friendlyName: 'Image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
        },
        {
          name: 'alt',
          friendlyName: 'Image `alt` description',
          type: 'string',
        },
        {
          name: 'sourceUrl',
          friendlyName: 'Video',
          type: 'url,',
        },
        {
          name: 'containerClasses',
          friendlyName: 'Container Classes',
          type: 'longText,',
        },
        {
          name: 'itemClasses',
          friendlyName: 'Item Classes',
          type: 'longText',
        },
      ],
    },
    {
      name: 'cta',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'longText',
        },
        {
          name: 'target',
          type: 'string',
        },
      ],
    },
  ],
})
