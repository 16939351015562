import { type Ref } from 'vue'
import {
  createDocumentCopy,
  type TDocumentCopy,
} from '@/domain/documents/services/documentClient'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import type { TSharingInformation } from '@/domain/cards/contracts/TSharingInformation'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { AxiosError } from 'axios'
import type { NotificationProps } from '@/domain/notifications/services/useNotificationsStore'
import { createNotification } from '@/domain/notifications/services/notificationFactory'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { CAuthenticationRouteNames } from '@/domain/Authentication'

export const handleCreateDocumentCopyGuardAction = async (
  information: Ref<TSharingInformation | null>,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  exposeOneNotification: (
    notification: NotificationProps,
    exposureDuration?: number,
  ) => void,
): Promise<boolean> => {
  if (
    !information.value ||
    !['copy', 'one-time-copy'].includes(information.value?.data.attributes.type)
  ) {
    return Promise.resolve(false)
  }

  const title = information.value?.data?.attributes?.documentTitle
    ? ` "${information.value.data.attributes.documentTitle}"`
    : ''

  let createDocumentResponse: TDocumentCopy | null = null

  try {
    createDocumentResponse = await createDocumentCopy({
      sourceDocumentId: information.value.data.attributes.sourceDocumentId,
      copyId: information.value.data.id,
    })
  } catch (error) {
    const { status, data } =
      ((error as AxiosError)?.response as {
        status: number
        data: {
          errors: {
            code: string
            detail: string
            status: string
          }[]
        }
      }) ?? {}

    const copyId = information.value.data.id

    // @info unauthorized
    if (status === 401) {
      if (!to.query?.email && !to.query?.submit) {
        const notification = createNotification(`Please login to access${title}`, 'error')
        exposeOneNotification(notification, 10)
      }

      captureWithUser(EUserEvents.visited, {
        route: to.name,
        route_query: { ...to.query },
        route_params: { ...to.params },
        guide_share_link: to.fullPath,
        guide_share_link_id: copyId,
        guard: 'shareTopicNavigationGuard',
        error: `Authentication is required to access case redirect to '${CAuthenticationRouteNames.signIn}'`,
        errorCode: '4370cc81',
        status: status,
      })

      next({
        name: CAuthenticationRouteNames.signIn,
        query: {
          share_id: copyId,
          ...to.query,
        },
        replace: true,
      })

      return Promise.resolve(true)
    }

    if (
      status === 422 &&
      [
        'Copy intention not found.',
        'Maximum number of copies reached',
        'Copy intention revoked.',
      ].includes(data?.errors[0]?.detail)
    ) {
      const notification = createNotification(
        `The case${title} copy was revoked.<br><br> Try another one or reach out to the author.`,
        'error',
      )
      exposeOneNotification(notification, 10)

      next({
        name: CRouteNames.home,
      })
      return Promise.resolve(true)
    }

    // @info specific error case
    if (status === 422) {
      const notification = createNotification(
        `The case${title} is currently not available.<br><br> Please try another one.`,
        'error',
      )
      exposeOneNotification(notification, 10)

      next({
        name: CRouteNames.home,
      })
      return Promise.resolve(true)
    }

    const notification = createNotification(
      `The case${title} is currently not available.<br><br> Please try another one.`,
      'error',
    )
    exposeOneNotification(notification, 10)

    next({
      name: CRouteNames.home,
    })
    return Promise.resolve(true)
  }

  // @info happy path
  next({
    name: CRouteNames.topic,
    params: { topicId: createDocumentResponse?.id },
    query: {
      ...to.query,
      document_title: createDocumentResponse?.attributes.title,
    },
    replace: true,
  })

  return Promise.resolve(true)
}
