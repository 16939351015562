import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/CaseMethodologySection.vue'
import { TCaseMethodologyStepIcons } from '@/domain/Content/contracts/TCaseMethodologyStepIcons'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'

export const CaseMethodologySectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'CaseMethodologySection',
  friendlyName: 'CaseMethodologySection',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'subline',
      type: 'longText',
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      defaultValue: 'mt-12 mb-6',
      type: 'string',
    },
    {
      name: 'ctaBox',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'longText',
        },
        {
          name: 'description',
          type: 'longText',
        },
        {
          name: 'cta',
          type: 'object',
          subFields: [
            {
              name: 'title',
              type: 'longText',
            },
            {
              name: 'target',
              type: 'string',
            },
          ],
        },
      ],
    },
    {
      friendlyName: 'Steps',
      name: 'steps',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'icon',
          type: 'string',
          enum: toKeyValue(TCaseMethodologyStepIcons),
        },
        {
          name: 'title',
          type: 'longText',
        },
        {
          name: 'description',
          type: 'longText',
        },
      ],
    },
  ],
})
