<template>
  <FlexibleSectionLayout
    v-if="props.title"
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClassesComputed"
    :content-container-classes="contentContainerClassesComputed"
  >
    <div class="w-full md:w-1/2">
      <div class="flex flex-col">
        <TextHeading
          :title-tag="EHeadline.H2"
          :content="{ title: props.title }"
          :classes="{ title: 'text-2xl', category: 'hidden' }"
        />

        <div class="mt-4">
          <span
            v-if="!isEmpty(props?.category)"
            class="inline grow-0 w-full rounded px-4 py-2 bg-base-150 text-base font-normal text-base-500"
            v-html="props.category"
          />
        </div>

        <p
          v-if="!isEmpty(props?.description)"
          class="text-base font-normal text-base-500 mt-4"
          v-html="props.description"
        />
      </div>
    </div>

    <ImageOrVideo
      v-if="props?.media"
      :source="
        props.media.type === EMediaType.image
          ? props.media.sourceFile
          : props.media.sourceUrl
      "
      :type="props.media.type"
      :alt="props.media.alt"
      :container-classes="props.media.containerClasses"
      :item-classes="props.media.itemClasses"
    />
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'CaseContentSection'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import ImageOrVideo from '@/domain/Content/components/ImageOrVideo.vue'
import { cva } from 'class-variance-authority'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import type { TCaseContentContent } from '@/domain/Content/contracts/TCaseContentContent'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { isEmpty } from 'lodash-es'
import { EMediaType } from '@/domain/Content/contracts/EMediaType'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    TCaseContentContent & {
      containerClasses?: string
      contentContainerClasses?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } & { attributes?: any }
  >(),
  {
    containerClasses: undefined,
    contentContainerClasses:
      'flex-col md:flex-row gap-12 xl:gap-14 2xl:gap-18 justify-center items-center lg:items-start',
  },
)

const containerClassesComputed = computed(() => cva()({ class: props.containerClasses }))

const contentContainerClassesComputed = computed(() =>
  cva()({ class: props.contentContainerClasses }),
)
</script>

<style scoped></style>
