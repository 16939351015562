import axios, { AxiosRequestConfig } from 'axios'
import { memoizedRemoteJwtResolver } from '@/app/services/remoteJwtResolver'
import apiErrorCodeLookup from '@/app/support/apiErrorCodeLookup'
import { log } from '@/app/services/errorService'
import { useConfig } from '@/app/services/useConfig'
import { Environment, SupportedEnvironments } from '@/app/support/environment'
import { flare } from '@flareapp/flare-client'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { captureWithUser } from '@/app/support/usePosthog'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import { useUserStore } from '@/app/services/useUserStore'

const requestInterceptor = (config) => {
  const storedJwt = useAuthentication().jwt.value

  if (storedJwt) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${storedJwt}`,
    }
  }

  return config
}

const requestErrorInterceptor = (error) => {
  log(error)
  flare.report(error, {
    type: 'Axios - Request error',
  })

  return Promise.reject(error)
}

const responseInterceptor = (response) => response

const responseErrorInterceptor = async (error, jwtResolver, axiosInstance) => {
  if (!error?.response) {
    log('Network/Server error', error)
    flare.report(error, {
      type: 'Axios - Network/Server error',
    })
    return Promise.reject(error)
  }

  if (
    error?.response?.data?.errors[0]?.code === '4370cc81' &&
    error?.response?.data?.errors[0]?.detail !== 'Token not provided'
  ) {
    captureWithUser(EAuthenticationEvents.receivedErrorJwtIsBlackListed, {
      error_code: error?.response?.data?.errors[0]?.code,
      error_detail: error?.response?.data?.errors[0]?.detail,
      request_url: error?.request?.responseURL,
    })

    const userProperties = useUserStore().reset()
    const authenticationContext = useAuthentication().reset()
    useAuthentication().removeJwt()

    captureWithUser(EAuthenticationEvents.forcefullyRedirectedToSignInPage, {
      error_code: error?.response?.data?.errors[0]?.code,
      error_detail: error?.response?.data?.errors[0]?.detail,
      request_url: error?.request?.responseURL,
      ...authenticationContext,
      ...userProperties,
    })

    // @todo trigger notification that survives the hard redirect
    window.location.href = '/sign-in'
  }

  if (
    error?.response?.data?.errors[0]?.code === apiErrorCodeLookup['JwtIsInvalidError']
  ) {
    const config = error?.config
    const jwt = await jwtResolver()

    if (jwt) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${jwt}`,
      }
    }

    return (axiosInstance.defaults.headers = {
      ...config.headers,
    })
  }

  // log(error)
  return Promise.reject(error)
}

export const resolveMiljnApiBaseUrl = () => {
  const config = useConfig()
  const mergeRequestId = config.get().version.mergeRequestId
  const environmentProvidedBaseUrl = import.meta.env.VITE_API_URL

  if (environmentProvidedBaseUrl) {
    return environmentProvidedBaseUrl
  }

  const baseUrls = {
    [SupportedEnvironments.mr]: `https://${mergeRequestId}.api.mr.miljn.org`,
    [SupportedEnvironments.local]: 'http://localhost',
    [SupportedEnvironments.staging]: 'https://api.staging.miljn.org',
    [SupportedEnvironments.production]: 'https://api.miljn.org',
  }

  return baseUrls[Environment.getName()]
}

const miljnApiBaseClientFactory = (withJwtAuth = true) => {
  const instanceSettings = {
    baseURL: resolveMiljnApiBaseUrl(),
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
    withCredentials: true,
  } as AxiosRequestConfig

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (XDEBUG_FRONTEND_IS_ENABLED === 'true') {
    instanceSettings.withCredentials = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // instanceSettings.headers['Cookie'] = 'XDEBUG_SESSION=PHPSTORM;'
    // @warn not working anymore 👆prevent set unsave header use `document.cookie = "XDEBUG_SESSION=PHPSTORM; path=/;"`
    //  but it's complicated and only works with fetch api somehow out of the box
  }

  const instance = axios.create(instanceSettings)

  if (!withJwtAuth) {
    return instance
  }

  const jwtResolver = memoizedRemoteJwtResolver(instance, 10000)

  instance.interceptors.request.use(requestInterceptor, requestErrorInterceptor)

  instance.interceptors.response.use(responseInterceptor, async (error) =>
    responseErrorInterceptor(error, jwtResolver, instance),
  )

  return instance
}

export default miljnApiBaseClientFactory
